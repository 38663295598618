import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import StandardHeader from "../assets/elements/StandardHeader";
import ThreeColumnUseCaseSection from "../assets/elements/ThreeColumnUseCaseSection";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import Layout from "../components/layout";
import Cta from "../partials/Cta";
import LeftColours from "../utilities/LeftColours";
import RightColours from "../utilities/RightColours";

import SvgProductAutomation from "../assets/icons/product-automation.svg";
import SvgProductDataQuality from "../assets/icons/product-data-quality.svg";
import SvgProductSDK2 from "../assets/icons/product-sdk_2.svg";

import SEO from "../components/seo";

const useCasesImageHeight = 198;
const useCasesImageWidth = 352;

const useCases = [
  {
    name: "Labeling I/O",
    description: "Read and write labels from and to Encord.",
    bullets: [
      "I/O in different label formats",
      "Filter and query labels",
      "Pre-built data loaders",
    ],
    image: (
      <SvgProductSDK2
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Label I/O"}
      />
    ),
  },
  {
    name: "Automate",
    description:
      "Minimise human effort & supervision through programmatic automation.",
    bullets: [
      "Build ensembles of micro-models",
      "Utilise automation feature suite",
      "Incorporate problem-specific heuristics",
    ],
    image: (
      <SvgProductAutomation
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Product automation"}
      />
    ),
  },
  {
    name: "Validate",
    description:
      "Get the highest quality model performance through active learning pipelines.",
    bullets: [
      "Discover classification & geometric errors",
      "Trigger automated quality assessments",
      "Import model predictions",
    ],
    image: (
      <SvgProductDataQuality
        width={useCasesImageWidth}
        height={useCasesImageHeight}
        alt={"Data quality"}
      />
    ),
  },
];

const Api = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <StandardHeader
        title={"API & SDK Tools to Streamline Computer Vision Data Pipelines"}
        description={
          <>
            Our SDK allows for easy building of workflow scripts to converge
            quickly to successful data strategies.
          </>
        }
        displayGitHubButton={true}
      />

      {/* Use cases */}
      <ThreeColumnUseCaseSection
        caseStudy={null}
        title={"How it works"}
        header={"Build data pipelines for any use case"}
        description={
          <>
            Build pipelines for annotation, validation, model training, and
            auditing.
          </>
        }
        useCases={useCases}
      />
      <br />

      {/*  Feature overview  */}
      <TwoFeatureOverview
        includeHeader={false}
        title={"Invest"}
        header={"Our investors 2"}
        description={"Our investors"}
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/feature-labeling-algo.png"}
            width={500}
            alt="Labeling algorithm"
          />
        }
        imageTwo={<img src="https://encord.cdn.prismic.io/encord/3ff0686a-00be-488f-859f-231d70b5a11e_feature-encord-code-sample.svg" alt="Code sample" width={0} height={0} className="w-auto h-auto" loading="lazy" />}
        headerOne={"Modular automation"}
        headerTwo={"Customise training data pipelines"}
        descriptionOne={
          "Compose labeling strategies with image clustering, classical computer vision algorithms, and state of the art deep learning techniques."
        }
        descriptionTwo={
          "Our APIs & SDK allow for easy building of workflow scripts to converge quickly into successful data strategies. Set up complex pipelines and integrations within minutes."
        }
      />

      <Cta
        location={location}
        pageName={"API Page"}
        ctaText={"Powerful API & SDK for Image Annotation and Segmentation"}
      />
    </Layout>
  );
};

export default Api;

export const Head = () => (
  <SEO
    title="SDK & API for AI-Assisted Image and Video Annotation | Encord"
    description="Quickly build custom scripts with our SDK to develop successful active learning pipelines and data strategies."
  />
);
